import React from 'react';
import { useTranslation } from 'react-i18next';
import { ExecutiveSummaryDaysFilter } from 'types';

import { SectionCard, SectionCardHeading } from '../../components';
import SummaryList from './List';

export interface IHardDataExecutiveSummary {
  mentions: number;
  sentiment: {
    negative: string;
    positive: string;
    neutral: string;
  };
}

interface IExecutiveSummaryProps {
  symbol?: string;
  filterDay?: ExecutiveSummaryDaysFilter;
  wrapperClassName?: string;
  hardData?: IHardDataExecutiveSummary;
}

export const ExecutiveSummary = (props: IExecutiveSummaryProps) => {
  const { t } = useTranslation();
  const { symbol, filterDay = 'daily', wrapperClassName, hardData } = props;

  return (
    <SectionCard noBorder nativeClassName={wrapperClassName}>
      <>
        <SectionCardHeading
          title={t('executiveSummary.mainTitle')}
          subtitle={t(`executiveSummary.${filterDay === 'daily' ? 'subtitleLastDay' : 'subtitleLastWeek'}`)}
        />
        <SummaryList symbol={symbol} filterDay={filterDay} hardData={hardData} />
      </>
    </SectionCard>
  );
};
